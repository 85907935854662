import React from "react"
import Layout from "../components/layout"

import Head from "../components/head"
import contactStyles from "./contact.module.scss"

const ContactPage = () => {
  return (
    <Layout>
      <Head title="Contact" />
      <h1>CONTACT INFORMATION</h1>
      <div>
        <ol className={contactStyles.infos}>
          <li className={contactStyles.post}>
            <h2>PHONE NUMBER</h2>
            <p>
              < a href="https://wa.me/+5511981001712">+55 11 98100-1712</a>
            </p>
            <h2>E-MAIL</h2>
            <p>
              < a href="mailto:export@qualiden.com.br">export@qualiden.com.br</a>
            </p>
          </li>
        </ol> 
      </div>
    </Layout>
  )
}

export default ContactPage
